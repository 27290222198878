const mixin = {
  methods: {
    genderIcon(gender) {
      if (gender) {
        switch (String(gender).toUpperCase()) {
          case "MALE":
            return "mdi-gender-male";
          case "FEMALE":
            return "mdi-gender-female";
          default:
            return "-";
        }
      }

      return null;
    }
  }
};

export default mixin;
