var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("competitorUser.title"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),(_vm.competitor)?_c('v-card-text',{staticClass:"pa-0"},[_c('v-simple-table',[_c('tbody',_vm._l((_vm.events),function(event){return _c('tr',{key:event.id},[_c('td',[_vm._v(_vm._s(event.name))]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'Live',
                  params: { id: event.id },
                  query: {
                    competitor: _vm.competitor.userId
                  }
                },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("competitorUser.viewResults"))+" ")])],1)])}),0)])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }