<template>
  <v-dialog
    v-model="show"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card :loading="isLoading">
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("competitorUser.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text v-if="competitor" class="pa-0">
        <v-simple-table>
          <tbody>
            <tr v-for="event in events" :key="event.id">
              <td>{{ event.name }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'Live',
                    params: { id: event.id },
                    query: {
                      competitor: competitor.userId
                    }
                  }"
                  target="_blank"
                >
                  {{ $t("competitorUser.viewResults") }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CompetitorUserDialog",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      isLoading: false,
      competitor: null,
      events: []
    };
  },
  computed: {
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById"
    })
  },
  methods: {
    ...mapActions({
      fetchUserEvents: "user/fetchUserEvents"
    }),
    openDialog(competitorId) {
      this.show = true;
      this.competitor = this.getCompetitorById(competitorId);
      this.fetchData();
    },
    closeDialog() {
      this.show = false;
    },
    fetchData() {
      this.isLoading = true;

      this.fetchUserEvents(this.competitor.userId)
        .then(response => {
          this.events = response.data.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
