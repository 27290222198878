<template>
  <v-dialog
    v-model="show"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("competitorImport.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-stepper v-model="step" vertical>
          <v-stepper-step step="1" :complete="step > 1">
            {{ $t("competitorImport.uploadFile") }}
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-file-input
              :label="$t('competitorImport.file')"
              v-model="$v.step1.file.$model"
              :loading="step1.loading"
              :error-messages="fileErrors"
            />

            <v-btn
              @click="submitStep1Handler"
              color="primary"
              :disabled="$v.step1.$invalid"
            >
              {{ $t("competitorImport.continue") }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="2" :complete="step > 2">
            {{ $t("competitorImport.assignFields") }}
          </v-stepper-step>

          <v-stepper-content step="2">
            <p :class="['my-4', validRowsColor]">
              {{
                $t("competitorImport.validAmount", {
                  amount: validRows.length,
                  count: rows.length
                })
              }}
            </p>

            <v-select
              v-model="step2.pagination.itemsPerPage"
              :items="itemsPerPageOptions"
              :label="$t('competitorImport.rowsPerPage')"
              style="width: 100px"
              class="ml-auto"
            />

            <v-simple-table
              v-if="step2.sheetData && step2.selectedColumns"
              id="sheetDataTable"
              class="mb-4"
              dense
            >
              <thead>
                <tr>
                  <th></th>
                  <th v-for="col in step2.selectedColumns" :key="col.id">
                    <v-select
                      :items="availableColumns"
                      v-model="col.value"
                      clearable
                      @input="
                        value => updateSelectedColumnHandler(col.id, value)
                      "
                    ></v-select>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(row, index) in pagedRows" :key="`row-${index}`">
                  <td class="text-right">
                    <v-btn @click="deleteRowHandler(index)" icon small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </td>
                  <v-tooltip
                    v-for="(col, colIndex) in Object.keys(row)"
                    :key="`col-${col}`"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <td
                        v-on="
                          validateCell(
                            row[col],
                            step2.selectedColumns[colIndex]
                              ? step2.selectedColumns[colIndex].value
                              : null
                          ).length === 0
                            ? null
                            : on
                        "
                        :class="
                          validateCell(
                            row[col],
                            step2.selectedColumns[colIndex]
                              ? step2.selectedColumns[colIndex].value
                              : null
                          ).length === 0
                            ? ''
                            : 'red accent-1'
                        "
                        style="white-space: nowrap"
                      >
                        {{
                          row[col] instanceof Date
                            ? $d(row[col], "shortDate")
                            : row[col]
                        }}
                      </td>
                    </template>
                    <p
                      v-for="error in validateCell(
                        row[col],
                        step2.selectedColumns[colIndex]
                          ? step2.selectedColumns[colIndex].value
                          : null
                      )"
                      :key="error.id"
                      class="pa-0 ma-0"
                    >
                      {{ error.message }}
                    </p>
                  </v-tooltip>
                </tr>
              </tbody>
            </v-simple-table>

            <v-pagination
              v-if="step2.pagination.itemsPerPage !== 'ALL'"
              v-model="step2.pagination.page"
              :length="pages"
              :total-visible="$vuetify.breakpoint.smAndUp ? 7 : 5"
              class="mb-4"
            />

            <v-btn
              @click="submitStep2Handler"
              color="primary"
              :disabled="$v.step2.$invalid"
            >
              {{ $t("competitorImport.continue") }}
            </v-btn>

            <v-btn @click="step--" text class="ml-2">
              {{ $t("competitorImport.back") }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="3" :complete="step > 3">
            {{ $t("competitorImport.uploadCompetitors") }}
          </v-stepper-step>

          <v-stepper-content step="3">
            <p :class="['my-4', successRowsColor]">
              {{
                $t("competitorImport.successAmount", {
                  amount: finishedUploads.length,
                  count: uploads.length
                })
              }}
            </p>

            <v-data-table
              v-if="step3.headers && step3.items"
              :headers="step3.headers"
              :items="step3.items"
              mobile-breakpoint="0"
              dense
            >
              <template v-slot:item.success="{ item }">
                <v-icon v-if="item.success" color="success">mdi-check</v-icon>

                <v-progress-circular
                  v-else-if="item.loading"
                  :size="15"
                  :width="2"
                  indeterminate
                />

                <v-btn
                  v-else
                  @click="uploadCompetitor(item, item.id)"
                  icon
                  color="error"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.competitor.userData.nation="{ item }">
                <CountryFlag
                  v-if="item.competitor.userData.nation"
                  :country="getNationById(item.competitor.userData.nation)"
                  :size="20"
                ></CountryFlag>
              </template>

              <template v-slot:item.competitor.userData.gender="{ item }">
                <v-icon small>
                  {{ genderIcon(item.competitor.userData.gender) }}
                </v-icon>
              </template>

              <template v-slot:item.competitor.userData.dateOfBirth="{ item }">
                {{
                  item.competitor.userData.dateOfBirth
                    ? $d(
                        new Date(
                          item.competitor.userData.dateOfBirth
                        ).getTime(),
                        "shortDate"
                      )
                    : ""
                }}
              </template>
            </v-data-table>

            <v-btn @click="submitStep3Handler" color="success">
              {{ $t("competitorImport.startUpload") }}
            </v-btn>

            <v-btn @click="step--" text class="ml-2">
              {{ $t("competitorImport.back") }}
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import XLSX from "xlsx";
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import CountryFlag from "@/components/CountryFlag";
import whitelist from "@/mixins/whitelist";
import genderIcon from "@/mixins/genderIcon";

export default {
  name: "CompetitorImportDialog",
  components: {
    CountryFlag
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      step: 1,
      step1: {
        loading: false,
        file: null
      },
      step2: {
        sheetData: null,
        selectedColumns: [],
        pagination: {
          itemsPerPage: 10,
          page: 1
        },
        startNumbers: null
      },
      step3: {
        headers: null,
        items: null
      },
      itemsPerPageOptions: [
        {
          value: 5,
          text: 5
        },
        {
          value: 10,
          text: 10
        },
        {
          value: 15,
          text: 15
        },
        {
          value: "ALL",
          text: this.$i18n.t("competitorImport.all")
        }
      ],
      allowedFilesTypes: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel"
      ],
      genders: ["MALE", "M", "H", "FEMALE", "F", "W", "L"]
    };
  },
  validations: {
    step1: {
      file: {
        required,
        fileType: function(value) {
          return value ? this.allowedFilesTypes.includes(value.type) : true;
        }
      }
    },
    step2: {
      sheetData: {
        required
      }
    }
  },
  watch: {
    file(newFile) {
      if (!this.$v.step1.file.$invalid) {
        this.step1.loading = true;
        this.step2.sheetData = null;

        const self = this;
        const reader = new FileReader();

        reader.onload = function(e) {
          let binary = "";
          const bytes = new Uint8Array(e.target.result);
          const length = bytes.byteLength;

          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const workbook = XLSX.read(binary, {
            type: "binary",
            cellDates: true,
            dateNF: "dd.MM.yyyy"
          });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          self.step2.sheetData = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            defval: null
          });

          self.step2.selectedColumns = new Array();
          for (let i = 0; i < self.step2.sheetData[0].length; i++) {
            self.step2.selectedColumns.push({
              id: i,
              value: self.getColumnValue(i)
            });
          }

          self.step1.loading = false;
        };

        reader.onerror = function() {
          self.step1.loading = false;
        };

        reader.readAsArrayBuffer(newFile);
      }
    }
  },
  computed: {
    ...mapGetters({
      isNation: "nations/isNation",
      getNationById: "nations/getItemById",
      getNationByValue: "nations/getItemByValue",
      hasColumnPreferences: "settings/hasColumnPreferences",
      getColumnPreferenceByIndex: "settings/getColumnPreferenceByIndex"
    }),
    file() {
      return this.step1.file;
    },
    fileErrors() {
      const errors = [];
      if (!this.$v.step1.file.$dirty) return errors;
      !this.$v.step1.file.required &&
        errors.push(this.$i18n.t("competitorImport.errors.file.required"));
      !this.$v.step1.file.fileType &&
        errors.push(this.$i18n.t("competitorImport.errors.file.fileType"));
      return errors;
    },
    pages() {
      if (this.step2.pagination.itemsPerPage === "ALL") {
        return 1;
      }

      return this.step2.sheetData
        ? Math.ceil(
            this.step2.sheetData.length / this.step2.pagination.itemsPerPage
          )
        : 1;
    },
    pagedRows() {
      if (this.step2.pagination.itemsPerPage === "ALL") {
        return this.step2.sheetData;
      }

      return this.step2.sheetData.slice(
        this.step2.pagination.itemsPerPage * (this.step2.pagination.page - 1),
        this.step2.pagination.itemsPerPage * this.step2.pagination.page
      );
    },
    availableColumns() {
      return this.event.competitorData.data.reduce((re, el) => {
        re.push({
          value: el.id,
          text: this.whitelist.includes(el.id)
            ? this.$i18n.t(`competitors.tables.${el.id}`)
            : el.view.label
        });

        if (el.id === "lastName" || el.id === "firstName") {
          if (
            re.findIndex(
              field =>
                field.value === "firstNameLastName" ||
                field.value === "lastNameFirstName"
            ) === -1
          ) {
            re.push(
              {
                value: "firstNameLastName",
                text: `${this.$i18n.t(
                  "competitors.tables.firstName"
                )} ${this.$i18n.t("competitors.tables.lastName")}`
              },
              {
                value: "lastNameFirstName",
                text: `${this.$i18n.t(
                  "competitors.tables.lastName"
                )} ${this.$i18n.t("competitors.tables.firstName")}`
              }
            );
          }
        }

        return re;
      }, []);
    },
    rows() {
      if (this.step2.sheetData) {
        return this.step2.sheetData;
      }

      return new Array();
    },
    validRows() {
      if (this.step2.sheetData) {
        return this.step2.sheetData.filter(row => this.isValidRow(row));
      }

      return new Array();
    },
    validRowsColor() {
      if (this.validRows.length === 0) {
        return "error--text";
      } else if (this.validRows.length === this.rows.length) {
        return "success--text";
      } else {
        return "warning--text";
      }
    },
    successRowsColor() {
      if (this.finishedUploads.length === 0) {
        return "error--text";
      } else if (this.finishedUploads.length === this.uploads.length) {
        return "success--text";
      } else {
        return "warning--text";
      }
    },
    uploads() {
      return this.step3.items || new Array();
    },
    finishedUploads() {
      if (this.step3.items !== null) {
        return this.step3.items.filter(item => item.success);
      }

      return new Array();
    },
    unfinishedUploads() {
      if (this.step3.items !== null) {
        return this.step3.items.filter(item => !item.success);
      }

      return new Array();
    }
  },
  mixins: [whitelist, genderIcon],
  methods: {
    ...mapMutations({
      setColumnPreference: "settings/setColumnPreference"
    }),
    ...mapActions({
      createCompetitorsForItem: "events/createCompetitorsForItem",
      createCompetitorForItem: "events/createCompetitorForItem"
    }),
    resetData() {
      this.step = 1;
      this.step1.file = null;
      this.step2.sheetData = null;
      this.step2.selectedColumns = new Array();
      this.$v.$reset();
    },
    openDialog() {
      this.resetData();
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
    getColumnValue(index) {
      const competitorData = this.event.competitorData.data;
      const competitorDataKeys = Object.keys(competitorData);

      if (this.hasColumnPreferences) {
        const columnValue = this.getColumnPreferenceByIndex(index);

        if (
          columnValue === "firstNameLastName" ||
          columnValue === "lastNameFirstName"
        ) {
          return columnValue;
        }

        const competitorDataField =
          competitorData.findIndex(el => el.id === columnValue) !== -1
            ? columnValue
            : null;

        if (competitorDataField === "startNumber") {
          this.buildStartNumberList(index);
        }

        return competitorDataField;
      } else if (competitorData[competitorDataKeys[index]] !== undefined) {
        const competitorDataField =
          competitorData[competitorDataKeys[index]].id;

        if (competitorDataField === "startNumber") {
          this.buildStartNumberList(index);
        }

        return competitorDataField;
      }

      return null;
    },
    updateSelectedColumnHandler(id, value) {
      for (let i = 0; i < this.step2.selectedColumns.length; i++) {
        if (
          this.step2.selectedColumns[i].id !== id &&
          this.step2.selectedColumns[i].value === value
        ) {
          Vue.set(this.step2.selectedColumns[i], "value", null);
        } else if (
          this.step2.selectedColumns[i].id !== id &&
          (value === "firstName" || value === "lastName") &&
          (this.step2.selectedColumns[i].value === "firstNameLastName" ||
            this.step2.selectedColumns[i].value === "lastNameFirstName")
        ) {
          Vue.set(this.step2.selectedColumns[i], "value", null);
        } else if (
          this.step2.selectedColumns[i].id !== id &&
          (value === "firstNameLastName" || value === "lastNameFirstName") &&
          (this.step2.selectedColumns[i].value === "firstName" ||
            this.step2.selectedColumns[i].value === "lastName" ||
            this.step2.selectedColumns[i].value === "firstNameLastName" ||
            this.step2.selectedColumns[i].value === "lastNameFirstName")
        ) {
          Vue.set(this.step2.selectedColumns[i], "value", null);
        }
      }

      if (value === "startNumber") {
        this.buildStartNumberList(id);
      }
    },
    deleteRowHandler(rowIndex) {
      if (this.step2.pagination.itemsPerPage === "ALL") {
        this.step2.sheetData.splice(rowIndex, 1);
      } else {
        this.step2.sheetData.splice(
          rowIndex +
            this.step2.pagination.itemsPerPage *
              (this.step2.pagination.page - 1),
          1
        );
      }
    },
    submitStep1Handler() {
      this.$v.step1.$touch();
      this.step++;
    },
    submitStep2Handler() {
      this.$v.step2.$touch();

      const startNumberIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "startNumber"
      );
      const firstNameIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "firstName"
      );
      const lastNameIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "lastName"
      );
      const firstNameLastNameIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "firstNameLastName"
      );
      const lastNameFirstNameIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "lastNameFirstName"
      );
      const genderIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "gender"
      );
      const dateOfBirthIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "dateOfBirth"
      );
      const nationIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "nation"
      );
      const clubIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "club"
      );
      const teamIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "team"
      );
      const classIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "class"
      );
      const emailIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "email"
      );

      this.step3.headers = this.step3.headers = [
        {
          text: this.$i18n.t("competitorImport.tables.success"),
          value: "success",
          align: "right"
        },
        ...this.event.competitorData.data.map(field => ({
          text: this.whitelist.includes(field.id)
            ? this.$i18n.t(`competitors.tables.${field.id}`)
            : field.view.label,
          value:
            field.id === "startNumber"
              ? "competitor.startNumber"
              : `competitor.userData.${field.id}`,
          sortable: false
        }))
      ];

      this.step3.items = this.validRows.map((row, index) => {
        let firstName = row[firstNameIndex] || row[firstNameIndex] || null;
        let lastName = row[lastNameIndex] || null;

        if (
          firstNameLastNameIndex !== -1 &&
          row[firstNameLastNameIndex] !== null
        ) {
          const firstNameLastName = row[firstNameLastNameIndex].split(" ");
          firstName = firstNameLastName[0];
          lastName = firstNameLastName[1];
        }

        if (
          lastNameFirstNameIndex !== -1 &&
          row[lastNameFirstNameIndex] !== null
        ) {
          const lastNameFirstName = row[lastNameFirstNameIndex].split(" ");
          lastName = lastNameFirstName[0];
          firstName = lastNameFirstName[1];
        }

        const trimRegex = /^(,\s*)|(\s*,)$/gm;
        if (lastName) lastName = lastName.trim().replace(trimRegex, "");
        if (firstName) firstName = firstName.trim().replace(trimRegex, "");

        const item = {
          id: `competitor-${index}`,
          competitor: {
            startNumber: row[startNumberIndex] || null,
            userData: {
              firstName,
              lastName,
              gender: this.getUnifiedGender(row[genderIndex]) || null,
              dateOfBirth:
                row[dateOfBirthIndex] !== null &&
                row[dateOfBirthIndex] instanceof Date
                  ? row[dateOfBirthIndex].toISOString().substr(0, 10)
                  : null,
              nation: this.getUnifiedNation(row[nationIndex]) || null,
              club: row[clubIndex] || null,
              team: row[teamIndex] || null,
              class: row[classIndex] || null,
              email: row[emailIndex] || null
            }
          },
          loading: false,
          success: false
        };

        const customFields = this.event.competitorData.data.slice(8);
        customFields.map(field => {
          const customFieldIndex = this.step2.selectedColumns.findIndex(
            el => el.value === field.id
          );
          if (
            row[customFieldIndex] !== null &&
            row[customFieldIndex] instanceof Date
          ) {
            item.competitor.userData[field.id] = row[customFieldIndex]
              .toISOString()
              .substr(0, 10);
          } else {
            item.competitor.userData[field.id] = row[customFieldIndex] || null;
          }
        });

        return item;
      });

      this.step2.selectedColumns.map((column, index) =>
        this.setColumnPreference({
          index,
          value: column.value
        })
      );

      this.step++;
    },
    submitStep3Handler() {
      const competitors = this.step3.items.filter(item => !item.success);

      this.createCompetitorsForItem({
        eventId: this.event.id,
        data: competitors.map((item, index) => {
          Vue.set(this.step3.items[index], "loading", true);

          return item.competitor;
        })
      })
        .then(() => {
          competitors.map((item, index) => {
            Vue.set(this.step3.items[index], "loading", false);
            Vue.set(this.step3.items[index], "success", true);
          });

          if (this.unfinishedUploads.length === 0) {
            this.closeDialog();
          }
        })
        .catch(() => {
          competitors.map(competitor => {
            const index = this.step3.items.findIndex(
              el => el.competitor.id === competitor.id
            );
            if (index !== -1) {
              Vue.set(this.step3.items[index], "loading", false);
            }
          });
        });
    },
    uploadCompetitor(item, index) {
      if (index !== -1) {
        Vue.set(this.step3.items[index], "loading", true);

        this.createCompetitorForItem({
          eventId: this.event.id,
          data: item.competitor
        })
          .then(() => {
            Vue.set(this.step3.items[index], "loading", false);
            Vue.set(this.step3.items[index], "success", true);
          })
          .catch(() => {
            Vue.set(this.step3.items[index], "loading", false);
          });
      }
    },
    isValidRow(row) {
      let isEmptyRow = true;

      for (let i = 0; i < row.length; i++) {
        if (
          this.validateCell(
            row[i],
            this.step2.selectedColumns[i]
              ? this.step2.selectedColumns[i].value
              : null
          ).length > 0
        ) {
          return false;
        }

        if (row[i] !== null) {
          isEmptyRow = false;
        }
      }

      return !isEmptyRow;
    },
    validateCell(value, fieldType) {
      if (fieldType !== null) {
        switch (fieldType) {
          case "startNumber":
            return this.validateStartNumber(value);
          case "firstName":
            return this.validateFirstName(value);
          case "lastName":
            return this.validateLastName(value);
          case "gender":
            return this.validateGender(value);
          case "dateOfBirth":
            return this.validateDateOfBirth(value);
          case "nation":
            return this.validateNation(value);
          case "club":
            return this.validateClub(value);
          case "team":
            return this.validateTeam(value);
          case "class":
            return this.validateClass(value);
          case "email":
            return this.validateEmail(value);
          default:
            return this.validateCustomField(value, fieldType);
        }
      }

      return new Array();
    },
    validateStartNumber(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[0].validation.required) {
          errors.push({
            id: "startNumber-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      } else {
        if (!Number.isInteger(value)) {
          errors.push({
            id: "startNumber-number",
            message: this.$i18n.t("competitorImport.validations.number")
          });
        }

        if (
          this.event.competitors.filter(el => el.startNumber === value).length >
          0
        ) {
          errors.push({
            id: "startNumber-taken",
            message: this.$i18n.t(
              "competitorImport.validations.startNumberTaken"
            )
          });
        }

        if (
          this.step2.startNumbers !== null &&
          this.step2.startNumbers.get(value) > 1
        ) {
          errors.push({
            id: "startNumber-notUnique",
            message: this.$i18n.t(
              "competitorImport.validations.startNumberNotUnique"
            )
          });
        }
      }

      return errors;
    },
    validateFirstName(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[1].validation.required) {
          errors.push({
            id: "firstName-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      }

      return errors;
    },
    validateLastName(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[2].validation.required) {
          errors.push({
            id: "lastName-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      }

      return errors;
    },
    validateGender(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[3].validation.required) {
          errors.push({
            id: "gender-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      } else {
        if (!this.genders.includes(String(value).toUpperCase())) {
          errors.push({
            id: "gender-gender",
            message: this.$i18n.t("competitorImport.validations.gender")
          });
        }
      }

      return errors;
    },
    validateDateOfBirth(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[4].validation.required) {
          errors.push({
            id: "dateOfBirth-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      } else {
        if (!(value instanceof Date)) {
          errors.push({
            id: "dateOfBirth-date",
            message: this.$i18n.t("competitorImport.validations.date")
          });
        }
      }

      return errors;
    },
    validateNation(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[5].validation.required) {
          errors.push({
            id: "nation-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      } else {
        if (!this.isNation(value)) {
          errors.push({
            id: "nation-nation",
            message: this.$i18n.t("competitorImport.validations.nation")
          });
        }
      }

      return errors;
    },
    validateClub(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[6].validation.required) {
          errors.push({
            id: "club-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      }

      return errors;
    },
    validateTeam(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[7].validation.required) {
          errors.push({
            id: "team-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      }

      return errors;
    },
    validateClass(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[8].validation.required) {
          errors.push({
            id: "class-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      }

      return errors;
    },
    validateEmail(value) {
      const errors = new Array();

      if (value === null) {
        if (this.event.competitorData.data[9].validation.required) {
          errors.push({
            id: "email-required",
            message: this.$i18n.t("competitorImport.validations.required")
          });
        }
      }

      return errors;
    },
    validateCustomField(value, fieldType) {
      const errors = new Array();
      const fieldIndex = this.event.competitorData.data.findIndex(
        el => el.id === fieldType
      );

      if (
        fieldIndex !== -1 &&
        this.event.competitorData.data[fieldIndex].validation.required &&
        value === null
      ) {
        errors.push({
          id: `${this.event.competitorData.data[fieldIndex].id}-required`,
          message: this.$i18n.t("competitorImport.validations.required")
        });
      }

      return errors;
    },
    getUnifiedGender(value) {
      switch (String(value).toUpperCase()) {
        case "MALE":
        case "M":
        case "H":
          return "MALE";
        case "FEMALE":
        case "F":
        case "W":
        case "L":
          return "FEMALE";
        default:
          return null;
      }
    },
    getUnifiedNation(value) {
      const nation = this.getNationByValue(value);
      return nation !== null ? nation.id : null;
    },
    buildStartNumberList(columnIndex) {
      this.step2.startNumbers = new Map();
      for (let i = 0; i < this.step2.sheetData.length; i++) {
        const entry = this.step2.sheetData[i][columnIndex];
        if (entry !== null) {
          if (this.step2.startNumbers.has(entry)) {
            this.step2.startNumbers.set(
              entry,
              this.step2.startNumbers.get(entry) + 1
            );
          } else {
            this.step2.startNumbers.set(entry, 1);
          }
        }
      }
    }
  }
};
</script>
<style scoped>
#sheetDataTable thead th:first-child,
#sheetDataTable tbody td:first-child {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
  background: #ffffff;
}
#sheetDataTable tbody tr:hover td:first-child {
  background: #eeeeee;
}
</style>
