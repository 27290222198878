<template>
  <v-tooltip v-if="country" top>
    <template v-slot:activator="{ on }">
      <v-avatar v-on="on" :size="`${size}px`" class="elevation-1">
        <v-img
          :src="`${baseURL}/${country.imageUrl}`"
          :alt="$t(`countries.${country.id}`)"
          :cover="true"
        ></v-img>
      </v-avatar>
    </template>
    <span>{{ $t(`countries.${country.id}`) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "CountryFlag",
  props: {
    country: {
      type: Object,
      default: null
    },
    size: {
      type: Number,
      default: 25
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL
    };
  }
};
</script>
