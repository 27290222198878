const mixin = {
  methods: {
    headers() {
      return this.event.competitorData.data
        .filter(field => {
          switch (field.id) {
            case "startNumber":
              return true;
            case "email":
              return false;
            default:
              return (
                field.accessControl.other.read ||
                (this.isOwner && field.accessControl.competitor.write)
              );
          }
        })
        .filter(field =>
          field.id === "firstName" || field.id === "lastName"
            ? this.getFieldPreferenceById("name")
            : this.getFieldPreferenceById(field.id)
        )
        .reduce((fields, field) => {
          if (field.id === "firstName" || field.id === "lastName") {
            if (!fields.some(el => el.value === "userData.name")) {
              const headerObj = {
                text: this.$i18n.t("competitors.tables.name"),
                value: "userData.name"
              };

              fields.push(headerObj);
            }
          } else {
            const headerObj = {
              text: this.whitelist.includes(field.id)
                ? this.$i18n.t(`competitors.tables.${field.id}`)
                : field.view.label
            };

            switch (field.id) {
              case "startNumber":
                headerObj.value = field.id;
                headerObj.align = "right";
                headerObj.width = "1%";
                break;
              default:
                headerObj.value = `userData.${field.id}`;
                break;
            }

            fields.push(headerObj);
          }

          return fields;
        }, []);
    }
  }
};

export default mixin;
