<template>
  <v-dialog v-model="show" width="500" scrollable>
    <v-form v-if="form.fields.length > 0" @submit.prevent="submitForm">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("forms.competitorCreate.title") }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="py-0">
          <v-container fluid>
            <v-row dense>
              <v-col
                cols="12"
                v-if="form.fields[0] && whitelist.includes(form.fields[0].id)"
              >
                <v-text-field
                  :name="form.fields[0].id"
                  v-model.number="form.fields[0].value"
                  type="number"
                  min="1"
                  max="9999"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[0])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[0].id}`) }}
                    <span
                      v-if="form.fields[0].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[1] && whitelist.includes(form.fields[1].id)"
              >
                <v-text-field
                  :name="form.fields[1].id"
                  v-model="form.fields[1].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[1])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[1].id}`) }}
                    <span
                      v-if="form.fields[1].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[2] && whitelist.includes(form.fields[2].id)"
              >
                <v-text-field
                  :name="form.fields[2].id"
                  v-model="form.fields[2].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[2])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[2].id}`) }}
                    <span
                      v-if="form.fields[2].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[3] && whitelist.includes(form.fields[3].id)"
              >
                <v-select
                  :name="form.fields[3].id"
                  :items="genders"
                  v-model="form.fields[3].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[3])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[3].id}`) }}
                    <span
                      v-if="form.fields[3].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="4"
                v-if="form.fields[4] && whitelist.includes(form.fields[4].id)"
              >
                <v-text-field
                  :name="form.fields[4].id"
                  v-model="form.fields[4].day"
                  :error-messages="dayErrors"
                  @focus="handleDateOfBirthFocus"
                >
                  <template v-slot:label>
                    {{ $t("competitors.tables.day") }}
                    <span
                      v-if="form.fields[4].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="4"
                v-if="form.fields[4] && whitelist.includes(form.fields[4].id)"
              >
                <v-text-field
                  :name="form.fields[4].id"
                  v-model="form.fields[4].month"
                  :error-messages="monthErrors"
                  @focus="handleDateOfBirthFocus"
                >
                  <template v-slot:label>
                    {{ $t("competitors.tables.month") }}
                    <span
                      v-if="form.fields[4].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="4"
                v-if="form.fields[4] && whitelist.includes(form.fields[4].id)"
              >
                <v-text-field
                  :name="form.fields[4].id"
                  v-model="form.fields[4].year"
                  :error-messages="yearErrors"
                  @focus="handleDateOfBirthFocus"
                >
                  <template v-slot:label>
                    {{ $t("competitors.tables.year") }}
                    <span
                      v-if="form.fields[4].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[5] && whitelist.includes(form.fields[5].id)"
              >
                <v-autocomplete
                  :name="form.fields[5].id"
                  :items="getItemsByLang"
                  v-model="form.fields[5].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[5])
                  "
                  auto-select-first
                  autocomplete="new-password"
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[5].id}`) }}
                    <span
                      v-if="form.fields[5].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[6] && whitelist.includes(form.fields[6].id)"
              >
                <v-text-field
                  :name="form.fields[6].id"
                  v-model="form.fields[6].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[6])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[6].id}`) }}
                    <span
                      v-if="form.fields[6].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[7] && whitelist.includes(form.fields[7].id)"
              >
                <v-text-field
                  :name="form.fields[7].id"
                  v-model="form.fields[7].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[7])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[7].id}`) }}
                    <span
                      v-if="form.fields[7].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[8] && whitelist.includes(form.fields[8].id)"
              >
                <v-text-field
                  :name="form.fields[8].id"
                  v-model="form.fields[8].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[8])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[8].id}`) }}
                    <span
                      v-if="form.fields[8].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-if="form.fields[9] && whitelist.includes(form.fields[9].id)"
              >
                <v-text-field
                  :name="form.fields[9].id"
                  v-model="form.fields[9].value"
                  :error-messages="
                    appendErrorMessage($v.form.fields.$each.$iter[9])
                  "
                >
                  <template v-slot:label>
                    {{ $t(`competitors.tables.${form.fields[9].id}`) }}
                    <span
                      v-if="form.fields[9].validation.required"
                      class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                v-for="(field, index) in customFields"
                :key="field.id"
              >
                <v-text-field
                  :name="field.id"
                  v-model="form.fields[index + basicFields.length].value"
                  :error-messages="
                    appendErrorMessage(
                      $v.form.fields.$each.$iter[index + basicFields.length]
                    )
                  "
                >
                  <template v-slot:label>
                    {{ field.view.label }}
                    <span v-if="field.validation.required" class="error--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-alert v-if="error" class="mt-6" dense outlined type="error">
              {{ $t(error) }}
            </v-alert>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-space-between">
          <v-btn @click="closeDialog" depressed :disabled="loading">
            {{ $t("forms.competitorCreate.cancel") }}
          </v-btn>

          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t("forms.competitorCreate.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
/* eslint-disable */

import { mapGetters, mapActions } from "vuex";
import whitelist from "@/mixins/whitelist";

export default {
  name: "TabCompetitorsCreate",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      form: {
        fields: this.event.competitorData.data.map(field => {
          if (field.id === "dateOfBirth") {
            return {
              day: "",
              month: "",
              year: "",
              ...field
            };
          }
          return {
            value: null,
            ...field
          };
        })
      },
      genders: [
        {
          value: "MALE",
          text: this.$i18n.t("forms.general.genders.male")
        },
        {
          value: "FEMALE",
          text: this.$i18n.t("forms.general.genders.female")
        }
      ],
      loading: false,
      error: null
    };
  },
  validations() {
    return {
      form: {
        fields: {
          $each: {
            value: {
              required: function(value, siblings) {
                if (siblings.day && siblings.month && siblings.year) {
                  return true;
                }
                if (siblings.validation.required) {
                  return value !== undefined && value !== null && value !== "";
                }
                return true;
              }
            },
            day: {
              required: function(value, siblings) {
                if (value === undefined) {
                  return true;
                }

                if (siblings.validation.required) {
                  return value !== "";
                }

                return true;
              },
              between: function(value, siblings) {
                if (value === "" || value === undefined) {
                  return true;
                }

                value = Number(value);
                if (isNaN(value)) {
                  return false;
                }

                if (value < 1 || value > 31) {
                  return false;
                }

                if (siblings && siblings.month && siblings.year) {
                  switch (Number(siblings.month)) {
                    case 1:
                    case 3:
                    case 5:
                    case 7:
                    case 8:
                    case 10:
                    case 12:
                      return value >= 1 && value <= 31;
                    case 2:
                      const year = Number(siblings.year);
                      if (!isNaN(year)) {
                        return (
                          value >= 1 &&
                          value <=
                            ((siblings.year % 4 === 0 &&
                              siblings.year % 100 !== 0) ||
                            siblings.year % 400 === 0
                              ? 29
                              : 28)
                        );
                      }
                      return true;
                    case 4:
                    case 6:
                    case 9:
                    case 11:
                      return value >= 1 && value <= 30;
                    default:
                      return true;
                  }
                }
                return true;
              }
            },
            month: {
              required: function(value, siblings) {
                if (value === undefined) {
                  return true;
                }

                if (siblings.validation.required) {
                  return value !== "";
                }

                return true;
              },
              between: function(value) {
                if (value === "" || value === undefined) {
                  return true;
                }

                value = Number(value);
                if (!isNaN(value)) {
                  return value >= 1 && value <= 12;
                }
                return false;
              }
            },
            year: {
              required: function(value, siblings) {
                if (value === undefined) {
                  return true;
                }

                if (siblings.validation.required) {
                  return value !== "";
                }

                return true;
              },
              between: function(value) {
                if (value === "" || value === undefined) {
                  return true;
                }

                value = Number(value);
                if (!isNaN(value)) {
                  const currentYear = new Date().getFullYear();
                  return value >= currentYear - 100 && value <= currentYear;
                }
                return false;
              }
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getItemsByLang: "nations/getItemsByLang"
    }),
    basicFields() {
      return this.form.fields.filter(el => this.whitelist.includes(el.id));
    },
    customFields() {
      return this.form.fields.slice(
        this.basicFields.length,
        this.form.fields.length
      );
    },
    dayErrors() {
      const errors = [];
      if (!this.$v.form.fields.$each.$iter[4].day.$dirty) return errors;
      this.$v.form.fields.$each.$iter[4].day.$invalid &&
        errors.push(this.$i18n.t("errors.day.notValid"));
      return errors;
    },
    monthErrors() {
      const errors = [];
      if (!this.$v.form.fields.$each.$iter[4].month.$dirty) return errors;
      this.$v.form.fields.$each.$iter[4].month.$invalid &&
        errors.push(this.$i18n.t("errors.month.notValid"));
      return errors;
    },
    yearErrors() {
      const errors = [];
      if (!this.$v.form.fields.$each.$iter[4].year.$dirty) return errors;
      this.$v.form.fields.$each.$iter[4].year.$invalid &&
        errors.push(this.$i18n.t("errors.year.notValid"));
      return errors;
    }
  },
  mixins: [whitelist],
  methods: {
    ...mapActions({
      createCompetitorForItem: "events/createCompetitorForItem"
    }),
    handleDateOfBirthFocus() {
      if (
        !this.form.fields[4].day &&
        !this.form.fields[4].month &&
        !this.form.fields[4].year
      ) {
        this.form.fields[4].day = "1";
        this.form.fields[4].month = "1";
        this.form.fields[4].year = String(new Date().getFullYear());
      }
    },
    appendErrorMessage(field) {
      const errors = [];
      if (!field.value.$dirty) return errors;
      !field.value.required &&
        errors.push(`${field.$model.view.label} is required`);
      return errors;
    },
    getCountryItem(item) {
      return this.$i18n.t(`countries.${item.id}`);
    },
    resetForm() {
      this.$v.form.$reset();

      this.form.fields.map((field, index) => {
        if (field.id === "dateOfBirth") {
          this.form.fields[index].value = {
            day: "",
            month: "",
            year: ""
          };
        } else {
          this.form.fields[index].value = null;
        }
      });
    },
    openDialog() {
      this.resetForm();
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
    submitForm() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.loading = true;
        this.error = null;

        const form = {
          startNumber: this.form.fields[0] ? this.form.fields[0].value : null,
          userData: {
            lastName: this.form.fields[1] ? this.form.fields[1].value : null,
            firstName: this.form.fields[2] ? this.form.fields[2].value : null,
            gender: this.form.fields[3]
              ? this.form.fields[3].value
                ? this.form.fields[3].value.toUpperCase()
                : null
              : null,
            dateOfBirth:
              this.form.fields[4].day &&
              this.form.fields[4].month &&
              this.form.fields[4].year
                ? `${this.form.fields[4].year}-${this.form.fields[4].month}-${this.form.fields[4].day}`
                : null,
            nation: this.form.fields[5] ? this.form.fields[5].value : null,
            club: this.form.fields[6] ? this.form.fields[6].value : null,
            team: this.form.fields[7] ? this.form.fields[7].value : null,
            class: this.form.fields[8] ? this.form.fields[8].value : null,
            email: this.form.fields[9] ? this.form.fields[9].value : null
          }
        };

        this.customFields.map((field, index) => {
          form.userData[field.id] = field.value;
        });

        const payload = {
          eventId: this.event.id,
          data: form
        };

        this.createCompetitorForItem(payload)
          .then(response => {
            this.closeDialog();
            this.loading = false;
          })
          .catch(response => {
            switch (response.data.status) {
              case -3010:
                this.error = "errors.competitor.startNumberTaken";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
            this.loading = false;
          });
      }
    }
  }
};
</script>
