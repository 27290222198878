const mixin = {
  methods: {
    cropTime(formatedTime) {
      let temp = String(formatedTime);
      let isNegative = false;

      if (temp.startsWith("-")) {
        isNegative = true;
        temp = temp.substring(1);
      }

      while (
        temp.length > 1 &&
        (temp.startsWith("0") || temp.startsWith(":"))
      ) {
        temp = temp.substring(1);
      }

      if (temp.startsWith(".")) {
        temp = `0${temp}`;
      }

      return isNegative ? `-${temp}` : temp;
    }
  }
};

export default mixin;
