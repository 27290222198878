var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-0"},[_c('v-card-text',{staticClass:"px-0 pb-0"},[(_vm.classFields.length > 0)?_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"label":_vm.$t('events.classFilter.class'),"items":_vm.classFilters,"return-object":""},model:{value:(_vm.classFilter),callback:function ($$v) {_vm.classFilter=$$v},expression:"classFilter"}})],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"resultsTable",attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"0","hide-default-footer":"","disable-pagination":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.label",fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.label))])]}},{key:"item.position.section.label",fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.position.section.label))])]}},{key:"item.partial.section.label",fn:function({ item }){return [(item.partial && item.partial.section.type === 'FINISH')?_c('span'):(item.partial)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.partial.section.label))]):_vm._e()]}},{key:"item.speed.section.label",fn:function({ item }){return [(item.speed)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.speed.section.label))]):_vm._e()]}},{key:"item.position.time",fn:function({ item }){return [(
            item.position.section.type === 'FINISH' &&
              item.state === 'CANCELLED' &&
              item.cancellation
          )?_c('span',[_vm._v(_vm._s(item.cancellation))]):(item.state !== 'INCOMPLETE' && item.position.time)?_c('span',[_vm._v(_vm._s(_vm.cropTime(_vm.formatRunTime(item.position.time, _vm.precision))))]):_c('span',[_vm._v(" - ")])]}},{key:"item.partial.time",fn:function({ item }){return [(
            item.group === 'TOTAL' ||
              (item.partial && item.partial.section.type === 'FINISH')
          )?_c('span'):(item.partial && item.partial.time)?_c('span',[_vm._v(_vm._s(_vm.cropTime(_vm.formatRunTime(item.partial.time, _vm.precision))))]):_c('span',[_vm._v(" - ")])]}},{key:"item.speed.speed",fn:function({ item }){return [(item.group === 'TOTAL' || !item.speed)?_c('span'):(item.speed && item.speed.speed)?_c('span',[_vm._v(_vm._s(_vm.convertToSpeedUnit(item.speed.speed)))]):_c('span',[_vm._v(" - ")])]}},{key:"item.position.diff",fn:function({ item }){return [(
            item.state !== 'INCOMPLETE' && item.position.diff !== undefined
          )?_c('span',[_vm._v(_vm._s(_vm.cropTime(_vm.formatRunTime(item.position.diff, _vm.precision))))]):_c('span',[_vm._v(" - ")])]}},{key:"item.partial.diff",fn:function({ item }){return [(
            item.group === 'TOTAL' ||
              (item.partial && item.partial.section.type === 'FINISH')
          )?_c('span'):(item.partial && item.partial.diff !== undefined)?_c('span',[_vm._v(_vm._s(_vm.cropTime(_vm.formatRunTime(item.partial.diff, _vm.precision))))]):_c('span',[_vm._v(" - ")])]}},{key:"item.position.rank",fn:function({ item }){return [(item.position.rank)?_c('span',[_vm._v(_vm._s(item.position.rank))]):_c('span',[_vm._v(" - ")])]}},{key:"item.partial.rank",fn:function({ item }){return [(
            item.group === 'TOTAL' ||
              (item.partial && item.partial.section.type === 'FINISH')
          )?_c('span'):(item.partial && item.partial.rank)?_c('span',[_vm._v(_vm._s(item.partial.rank))]):_c('span',[_vm._v(" - ")])]}},{key:"item.speed.rank",fn:function({ item }){return [(item.group === 'TOTAL' || !item.speed)?_c('span'):(item.speed && item.speed.rank)?_c('span',[_vm._v(" "+_vm._s(item.speed.rank)+" ")]):_c('span',[_vm._v(" - ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }