<template>
  <v-card class="px-0">
    <v-card-text class="px-0 pb-0">
      <v-container v-if="classFields.length > 0">
        <v-row dense>
          <v-col cols="auto">
            <v-select
              :label="$t('events.classFilter.class')"
              :items="classFilters"
              v-model="classFilter"
              return-object
            />
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        class="resultsTable"
        :headers="headers"
        :items="items"
        mobile-breakpoint="0"
        hide-default-footer
        disable-pagination
        disable-sort
        dense
      >
        <template v-slot:item.label="{ item }">
          <span class="font-weight-bold">{{ item.label }}</span>
        </template>

        <template v-slot:item.position.section.label="{ item }">
          <span class="font-weight-bold">{{
            item.position.section.label
          }}</span>
        </template>

        <template v-slot:item.partial.section.label="{ item }">
          <span
            v-if="item.partial && item.partial.section.type === 'FINISH'"
          ></span>
          <span v-else-if="item.partial" class="font-weight-bold">{{
            item.partial.section.label
          }}</span>
        </template>

        <template v-slot:item.speed.section.label="{ item }">
          <span v-if="item.speed" class="font-weight-bold">{{
            item.speed.section.label
          }}</span>
        </template>

        <template v-slot:item.position.time="{ item }">
          <span
            v-if="
              item.position.section.type === 'FINISH' &&
                item.state === 'CANCELLED' &&
                item.cancellation
            "
            >{{ item.cancellation }}</span
          >
          <span v-else-if="item.state !== 'INCOMPLETE' && item.position.time">{{
            cropTime(formatRunTime(item.position.time, precision))
          }}</span>
          <span v-else> - </span>
        </template>

        <template v-slot:item.partial.time="{ item }">
          <span
            v-if="
              item.group === 'TOTAL' ||
                (item.partial && item.partial.section.type === 'FINISH')
            "
          ></span>
          <span v-else-if="item.partial && item.partial.time">{{
            cropTime(formatRunTime(item.partial.time, precision))
          }}</span>
          <span v-else> - </span>
        </template>

        <template v-slot:item.speed.speed="{ item }">
          <span v-if="item.group === 'TOTAL' || !item.speed"></span>
          <span v-else-if="item.speed && item.speed.speed">{{
            convertToSpeedUnit(item.speed.speed)
          }}</span>
          <span v-else> - </span>
        </template>

        <template v-slot:item.position.diff="{ item }">
          <span
            v-if="
              item.state !== 'INCOMPLETE' && item.position.diff !== undefined
            "
            >{{ cropTime(formatRunTime(item.position.diff, precision)) }}</span
          >
          <span v-else> - </span>
        </template>

        <template v-slot:item.partial.diff="{ item }">
          <span
            v-if="
              item.group === 'TOTAL' ||
                (item.partial && item.partial.section.type === 'FINISH')
            "
          ></span>
          <span v-else-if="item.partial && item.partial.diff !== undefined">{{
            cropTime(formatRunTime(item.partial.diff, precision))
          }}</span>
          <span v-else> - </span>
        </template>

        <template v-slot:item.position.rank="{ item }">
          <span v-if="item.position.rank">{{ item.position.rank }}</span>
          <span v-else> - </span>
        </template>

        <template v-slot:item.partial.rank="{ item }">
          <span
            v-if="
              item.group === 'TOTAL' ||
                (item.partial && item.partial.section.type === 'FINISH')
            "
          ></span>
          <span v-else-if="item.partial && item.partial.rank">{{
            item.partial.rank
          }}</span>
          <span v-else> - </span>
        </template>

        <template v-slot:item.speed.rank="{ item }">
          <span v-if="item.group === 'TOTAL' || !item.speed"></span>
          <span v-else-if="item.speed && item.speed.rank">
            {{ item.speed.rank }}
          </span>
          <span v-else> - </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import whitelist from "@/mixins/whitelist";
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";
import convertToSpeedUnit from "@/mixins/convertToSpeedUnit";

export default {
  name: "CompetitorRunDetails",
  props: {
    event: {
      type: Object,
      required: true
    },
    competitor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      classFilter: {
        text: this.$i18n.t("events.classFilter.all"),
        value: "all"
      },
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"]
    };
  },
  computed: {
    classFields() {
      return this.event.competitorData.data.filter(
        el => el.settings.classDivision
      );
    },
    classFieldValues() {
      return this.classFields.map(el => ({
        field: el,
        value: this.competitor.userData[el.id]
      }));
    },
    classFilters() {
      return [
        {
          text: this.$i18n.t("events.classFilter.all"),
          value: "all"
        },
        ...this.classFieldValues.map(el => {
          return {
            text:
              (this.whitelist.includes(el.field.id)
                ? this.$i18n.t(`competitors.tables.${el.field.id}`)
                : el.field.view.label) +
              ": " +
              el.value,
            value: {
              key: el.field.id,
              value: el.value
            }
          };
        })
      ];
    },
    precision() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );
      return precision !== -1 ? precision : 2;
    },
    headers() {
      return [
        {
          text: this.$i18n.t("competitors.tables.run"),
          value: "label"
        },
        {
          text: this.$i18n.t("competitors.tables.section"),
          value: "position.section.label"
        },
        {
          text: this.$i18n.t("competitors.tables.time"),
          value: "position.time",
          align: "right"
        },
        {
          text: this.$i18n.t("competitors.tables.diff"),
          value: "position.diff",
          align: "right"
        },
        {
          text: this.$i18n.t("competitors.tables.rank"),
          value: "position.rank",
          align: "right"
        },
        {
          text: this.$i18n.t("competitors.tables.section"),
          value: "partial.section.label"
        },
        {
          text: this.$i18n.t("competitors.tables.time"),
          value: "partial.time",
          align: "right"
        },
        {
          text: this.$i18n.t("competitors.tables.diff"),
          value: "partial.diff",
          align: "right"
        },
        {
          text: this.$i18n.t("competitors.tables.rank"),
          value: "partial.rank",
          align: "right"
        },
        {
          text: this.$i18n.t("competitors.tables.section"),
          value: "speed.section.label"
        },
        {
          text: this.$i18n.t("competitors.tables.speed"),
          value: "speed.speed",
          align: "right"
        },
        {
          text: this.$i18n.t("competitors.tables.rank"),
          value: "speed.rank",
          align: "right"
        }
      ];
    },
    items() {
      const speedSections = new Map();
      this.event.setup.sections
        .filter(section => section.hasOwnProperty("distance"))
        .map((section, index) => speedSections.set(section.type, index + 1));

      const items = this.event.heats
        .map(heat => {
          const sections = this.event.setup.sections
            .slice(1)
            .map((section, index) => {
              const row = new Object();
              const sectionRankingEntries = heat.ranking.sectionRanking.filter(
                el => el.section === section.type
              );
              const heatCycleEntry = heat.heatCycle.entries.find(
                el => el.competitorId === this.competitor.id
              );
              row.group = "HEAT";
              row.state = heatCycleEntry ? heatCycleEntry.state : null;
              row.cancellation =
                heatCycleEntry && heatCycleEntry.state === "CANCELLED"
                  ? heatCycleEntry.data.cancellation
                  : null;

              row.position = {
                section: {
                  type: section.type,
                  label: section.type === "FINISH" ? "FIN" : `INT${index + 1}`
                }
              };
              const sectionTimeEntries =
                sectionRankingEntries.length > 0
                  ? sectionRankingEntries.filter(
                      el => el.type === "SECTION_TIME"
                    )
                  : null;
              const sectionTimeEntry =
                sectionTimeEntries && sectionTimeEntries.length > 0
                  ? sectionTimeEntries[0].entries.find(
                      el => el.competitorId === this.competitor.id
                    )
                  : null;
              if (sectionTimeEntry) {
                const sectionTimeLeaderTime = this.getSectionTimeLeaderTime(
                  heat,
                  section.type
                );
                row.position.time = sectionTimeEntry.value;
                row.position.diff = sectionTimeLeaderTime
                  ? sectionTimeEntry.value - sectionTimeLeaderTime
                  : null;
                if (this.classFilter.value === "all") {
                  row.position.rank = sectionTimeEntry.rank;
                } else if (section.type === "FINISH") {
                  const classDivision = heat.ranking.classDivisionRanking[
                    this.classFilter.value.key
                  ].find(el => el.key === this.classFilter.value.value);
                  if (classDivision) {
                    const classDivisionEntry = classDivision.entries.find(
                      el => el.competitorId === this.competitor.id
                    );
                    if (classDivisionEntry) {
                      row.position.rank = classDivisionEntry.rank;
                    }
                  }
                }
              }

              row.partial = {
                section: {
                  type: section.type,
                  label:
                    index < this.event.setup.sections.length - 3
                      ? `I${index + 1}-I${index + 2}`
                      : `I${index + 1}-FIN`
                }
              };
              if (section.type !== "FINISH") {
                const sectionRankingEntries = heat.ranking.sectionRanking.filter(
                  el => el.section === this.event.setup.sections[index + 2].type
                );
                const splitTimeEntries =
                  sectionRankingEntries.length > 0
                    ? sectionRankingEntries.filter(
                        el => el.type === "SPLIT_TIME"
                      )
                    : null;
                const splitTimeEntry =
                  splitTimeEntries && splitTimeEntries.length > 0
                    ? splitTimeEntries[0].entries.find(
                        el => el.competitorId === this.competitor.id
                      )
                    : null;
                if (splitTimeEntry) {
                  const splitTimeLeaderTime = this.getSplitTimeLeaderTime(
                    heat,
                    this.event.setup.sections[index + 2].type
                  );
                  row.partial.time = splitTimeEntry.value;
                  row.partial.diff = splitTimeLeaderTime
                    ? splitTimeEntry.value - splitTimeLeaderTime
                    : null;
                  if (this.classFilter.value === "all") {
                    row.partial.rank = splitTimeEntry.rank;
                  }
                }
              }

              if (speedSections.has(section.type)) {
                row.speed = {
                  section: {
                    type: section.type,
                    label: `Sp${speedSections.get(section.type)}`
                  }
                };
                const speedEntries =
                  sectionRankingEntries.length > 0
                    ? sectionRankingEntries.filter(el => el.type === "SPEED")
                    : null;
                const speedEntry =
                  speedEntries && speedEntries.length > 0
                    ? speedEntries[0].entries.find(
                        el => el.competitorId === this.competitor.id
                      )
                    : null;
                if (speedEntry) {
                  row.speed.speed = speedEntry.value;
                  if (this.classFilter.value === "all") {
                    row.speed.rank = speedEntry.rank;
                  }
                }
              }

              return row;
            });

          sections[0].label = heat.name;

          return sections;
        })
        .flat();

      const totalSections = this.event.setup.sections
        .slice(1)
        .map((section, index) => {
          const row = new Object();
          const overallRankingEntry = this.event.overallRanking.entries.find(
            el => el.competitorId === this.competitor.id
          );
          row.group = "TOTAL";
          row.state = overallRankingEntry ? overallRankingEntry.state : null;
          row.position = {
            section: {
              type: section.type,
              label: section.type === "FINISH" ? "FIN" : `INT${index + 1}`
            }
          };

          if (section.type === "FINISH") {
            if (overallRankingEntry) {
              const overallRankingLeaderTime = this.getOverallRankingLeaderTime();

              row.position.time = overallRankingEntry.runTime;
              row.position.diff = overallRankingLeaderTime
                ? overallRankingEntry.runTime - overallRankingLeaderTime
                : null;
              if (this.classFilter.value === "all") {
                row.position.rank = overallRankingEntry.rank;
              } else {
                const classDivision = this.event.overallRanking.classDivisionRanking[
                  this.classFilter.value.key
                ].find(el => el.key === this.classFilter.value.value);
                if (classDivision) {
                  const classDivisionEntry = classDivision.entries.find(
                    el => el.competitorId === this.competitor.id
                  );
                  if (classDivisionEntry) {
                    row.position.rank = classDivisionEntry.rank;
                  }
                }
              }
            }
          } else {
            const competitorTotalSectionTime = this.getTotalSectionTime(
              this.competitor.id,
              section.type
            );
            const leaderTotalSectionTime =
              this.event.overallRanking.entries.length > 0
                ? this.getTotalSectionTime(
                    this.event.overallRanking.entries[0].competitorId,
                    section.type
                  )
                : null;
            row.position.time = competitorTotalSectionTime;
            row.position.diff =
              competitorTotalSectionTime && leaderTotalSectionTime
                ? competitorTotalSectionTime - leaderTotalSectionTime
                : null;
          }

          return row;
        });
      totalSections[0].label = this.$i18n.t("events.total");

      return items.concat(totalSections);
    }
  },
  mixins: [whitelist, formatRunTime, cropTime, convertToSpeedUnit],
  methods: {
    getSectionTimeLeaderTime(heat, section) {
      const sectionTimeEntries = heat.ranking.sectionRanking.filter(
        el => el.section === section && el.type === "SECTION_TIME"
      );
      if (
        sectionTimeEntries.length > 0 &&
        sectionTimeEntries[0].entries.length > 0
      ) {
        return sectionTimeEntries[0].entries[0].value;
      }
      return null;
    },
    getSplitTimeLeaderTime(heat, section) {
      const splitTimeEntries = heat.ranking.sectionRanking.filter(
        el => el.section === section && el.type === "SPLIT_TIME"
      );
      if (
        splitTimeEntries.length > 0 &&
        splitTimeEntries[0].entries.length > 0
      ) {
        return splitTimeEntries[0].entries[0].value;
      }
      return null;
    },
    getOverallRankingLeaderTime() {
      if (
        this.event.overallRanking.entries.length > 0 &&
        this.event.overallRanking.entries[0].runTime > 0 &&
        this.event.overallRanking.entries[0].state === "FINISHED"
      ) {
        return this.event.overallRanking.entries[0].runTime;
      }
      return null;
    },
    getTotalSectionTime(competitorId, section) {
      const totalHeatsRunTime = this.event.heats
        .slice(0, -1)
        .filter(el => !el.excludeFromOverallRanking)
        .reduce((re, el) => {
          const rankingEntry = el.ranking.entries.find(
            entry => entry.competitorId === competitorId
          );
          if (rankingEntry) {
            return re + rankingEntry.runTime;
          }
          return re;
        }, 0);

      const heatCycleEntry = this.event.heats[
        this.event.heats.length - 1
      ].heatCycle.entries.find(el => el.competitorId === competitorId);
      if (heatCycleEntry && heatCycleEntry.data.sections[section]) {
        return (
          totalHeatsRunTime + heatCycleEntry.data.sections[section].sectionTime
        );
      }

      return null;
    }
  }
};
</script>
<style>
.resultsTable th,
.resultsTable td {
  white-space: nowrap;
}
</style>
