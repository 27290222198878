const mixin = {
  data() {
    return {
      whitelist: [
        "startNumber",
        "lastName",
        "firstName",
        "email",
        "gender",
        "dateOfBirth",
        "nation",
        "club",
        "team",
        "class"
      ]
    };
  }
};

export default mixin;
