<template>
  <v-dialog
    v-model="show"
    width="1000"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title
          v-if="
            competitor &&
              competitor.startNumber &&
              competitor.userData.lastName &&
              competitor.userData.firstName
          "
        >
          {{ competitor.startNumber }} - {{ competitor.userData.lastName }}
          {{ competitor.userData.firstName }}
        </v-toolbar-title>
        <v-toolbar-title v-else>
          {{ $t("competitorDialog.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-tabs v-if="isOwner" v-model="activeTab" icons-and-text grow>
        <v-tab v-for="tab in tabs" :key="tab.id">
          {{ tab.name }}
          <v-icon>{{ tab.icon }}</v-icon>
        </v-tab>

        <v-tab-item key="competitorData">
          <CompetitorEditForm
            ref="competitorEditForm"
            :event="event"
            :competitor="competitor"
          />
        </v-tab-item>

        <v-tab-item key="competitorResults">
          <CompetitorRunDetails :event="event" :competitor="competitor" />
        </v-tab-item>
      </v-tabs>

      <CompetitorRunDetails v-else :event="event" :competitor="competitor" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import CompetitorRunDetails from "@/components/CompetitorRunDetails";
import CompetitorEditForm from "@/components/CompetitorEditForm";

export default {
  name: "CompetitorDialog",
  components: {
    CompetitorRunDetails,
    CompetitorEditForm
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      competitor: null,
      activeTab: 0
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    tabs() {
      return [
        {
          id: "competitorData",
          name: this.$i18n.t("competitorDialog.tabs.competitorData"),
          icon: "mdi-account-edit"
        },
        {
          id: "competitorResults",
          name: this.$i18n.t("competitorDialog.tabs.competitorResults"),
          icon: "mdi-run"
        }
      ];
    }
  },
  methods: {
    openDialog(competitor) {
      this.competitor = competitor;
      this.initData();
      this.show = true;
    },
    closeDialog() {
      this.show = false;
      this.activeTab = 0;
    },
    initData() {
      this.$nextTick(() => {
        if (this.$refs.competitorEditForm) {
          this.$refs.competitorEditForm.initData();
        }
      });
    }
  }
};
</script>
