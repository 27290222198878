<template>
  <v-card flat>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row>
        <v-col
          :cols="isOwner ? '10' : '12'"
          sm="3"
          :class="isOwner ? 'offset-sm-8' : 'offset-sm-9'"
        >
          <v-text-field
            :label="$t('events.search')"
            v-model="search.competitor"
            append-icon="mdi-magnify"
            single-line
            clearable
          ></v-text-field>
        </v-col>

        <v-col
          v-if="isOwner"
          cols="1"
          sm="1"
          class="d-flex justify-center align-center"
        >
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>

            <v-list flat>
              <v-subheader>
                {{ $t("events.competitors.actions.groups.competitors") }}
              </v-subheader>
              <v-list-item-group>
                <v-list-item @click="openCompetitorCreateDialog">
                  {{ $t("forms.competitorCreate.action") }}
                </v-list-item>
                <v-list-item @click="openCompetitorImportDialog">
                  {{ $t("competitorImport.action") }}
                </v-list-item>
                <v-list-item @click="deleteCompetitorsHandler">
                  {{ $t("competitors.delete.all.label") }}
                </v-list-item>
                <v-list-item
                  @click="notifyCompetitorsHandler"
                  :disabled="event.competitors.length === 0"
                >
                  {{ $t("competitors.notify.label") }}
                </v-list-item>
              </v-list-item-group>

              <v-subheader>
                {{ $t("events.competitors.actions.groups.startNumbers") }}
              </v-subheader>
              <v-list-item-group>
                <v-list-item @click="assignStartNumbers(true)">
                  {{ $t("competitors.startNumber.all") }}
                </v-list-item>

                <v-list-item @click="assignStartNumbers(false)">
                  {{ $t("competitors.startNumber.missing") }}
                </v-list-item>

                <v-list-item @click="resetStartNumbers">
                  {{ $t("competitors.startNumber.reset") }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="isOwner ? competitorHeadersOwner : competitorHeaders"
      :items="competitors"
      :search="search.competitor"
      sort-by="startNumber"
      mobile-breakpoint="0"
      dense
    >
      <template v-slot:item.startNumber="{ item }">
        <a @click="openCompetitorDetailDialog(item)">
          {{ item.startNumber }}
        </a>
      </template>

      <template v-slot:item.userData.name="{ item }">
        <div class="d-flex align-center" style="column-gap: 6px;">
          <span style="white-space: nowrap">
            {{ getCompetitorName(item) }}
          </span>
          <v-btn
            v-if="item.userId"
            icon
            small
            @click="openCompetitorUserDialog(item.id)"
          >
            <v-icon small>mdi-star</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.userData.nation="{ item }">
        <CountryFlag
          v-if="item.userData.nation"
          :country="getItemById(item.userData.nation)"
          :size="20"
        ></CountryFlag>
      </template>

      <template v-slot:item.userData.gender="{ item }">
        <v-icon small>
          {{ genderIcon(item.userData.gender) }}
        </v-icon>
      </template>

      <template v-slot:item.userData.dateOfBirth="{ item }">
        {{
          item.userData.dateOfBirth
            ? isNaN(new Date(item.userData.dateOfBirth))
              ? item.userData.dateOfBirth
              : $d(new Date(item.userData.dateOfBirth).getTime(), "shortDate")
            : ""
        }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-btn icon @click="openCompetitorDetailDialog(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            icon
            :disabled="!isCompetitorDeletable(item.id)"
            @click="deleteCompetitorHandler(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <TabCompetitorsCreate ref="competitorCreateDialog" :event="event" />

    <CompetitorDialog ref="competitorDetailDialog" :event="event" />

    <CompetitorImportDialog ref="competitorImportDialog" :event="event" />

    <CompetitorUserDialog ref="competitorUserDialog" :event="event" />
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapActions } from "vuex";
import TabCompetitorsCreate from "@/components/TabCompetitorsCreate";
import CompetitorDialog from "@/components/CompetitorDialog";
import CompetitorImportDialog from "@/components/CompetitorImportDialog";
import CompetitorUserDialog from "@/components/CompetitorUserDialog";
import CountryFlag from "@/components/CountryFlag";
import whitelist from "@/mixins/whitelist";
import genderIcon from "@/mixins/genderIcon";
import getCompetitorName from "@/mixins/getCompetitorName";
import headers from "@/mixins/headers";

export default {
  name: "TabCompetitors",
  components: {
    TabCompetitorsCreate,
    CompetitorDialog,
    CompetitorImportDialog,
    CompetitorUserDialog,
    CountryFlag
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      loading: false,
      showCreateDialog: false,
      showCompetitorImportDialog: false,
      search: {
        competitor: ""
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      event: state => state.events.selectedItem,
      competitors: state => state.events.selectedItem.competitors
    }),
    ...mapGetters({
      getItemById: "nations/getItemById",
      getFieldPreferenceById: "settings/getFieldPreferenceById"
    }),
    competitorHeaders() {
      const headers = Array.from(this.headers());
      return headers;
    },
    competitorHeadersOwner() {
      const headers = Array.from(this.competitorHeaders);
      headers.push({
        value: "actions",
        sortable: false,
        width: "1%"
      });
      return headers;
    },
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    }
  },
  mixins: [whitelist, genderIcon, getCompetitorName, headers],
  methods: {
    ...mapActions({
      deleteCompetitor: "events/deleteCompetitor",
      deleteCompetitors: "events/deleteCompetitors",
      setCompetitorStartnumbers: "events/setCompetitorStartnumbers",
      sendCompetitorsEmail: "events/sendCompetitorsEmail"
    }),
    openCompetitorCreateDialog() {
      this.$refs.competitorCreateDialog.openDialog();
    },
    openCompetitorDetailDialog(competitor) {
      this.$refs.competitorDetailDialog.openDialog(competitor);
    },
    openCompetitorImportDialog() {
      this.$refs.competitorImportDialog.openDialog();
    },
    openCompetitorUserDialog(competitorId) {
      this.$refs.competitorUserDialog.openDialog(competitorId);
    },
    isCompetitorDeletable(competitorId) {
      for (const heat of this.event.heats) {
        for (const entry of heat.heatCycle.entries) {
          if (entry.competitorId === competitorId) {
            return false;
          }
        }
      }

      return true;
    },
    deleteCompetitorHandler(competitor) {
      const payload = {
        eventId: this.event.id,
        competitorId: competitor.id
      };

      confirm(
        this.$i18n.t("competitors.delete.single.confirmation", {
          competitor: `${competitor.userData.firstName} ${competitor.userData.lastName}`
        })
      ) && this.deleteCompetitor(payload);
    },
    deleteCompetitorsHandler() {
      const competitors = this.event.competitors;
      const deletableCompetitors = this.event.competitors
        .filter(el => this.isCompetitorDeletable(el.id))
        .map(el => el.id);

      const payload = {
        eventId: this.event.id,
        data: deletableCompetitors
      };

      if (
        confirm(
          this.$i18n.t("competitors.delete.all.confirmation", {
            deletableCompetitors: deletableCompetitors.length,
            competitors: competitors.length
          })
        )
      ) {
        this.deleteCompetitors(payload).catch(() => {});
      }
    },
    notifyCompetitorsHandler() {
      const competitors = this.event.competitors.length;
      const notifiableCompetitors = this.event.competitors.filter(
        el => el.userData.email
      ).length;

      if (
        confirm(
          this.$i18n.t("competitors.notify.confirmation", {
            notifiableCompetitors,
            competitors
          })
        )
      ) {
        const payload = {
          eventId: this.event.id
        };

        this.sendCompetitorsEmail(payload)
          .then(response => {
            this.$emit("notify", true, response);
          })
          .catch(response => {
            this.$emit("notify", false, response);
          });
      }
    },
    resetStartNumbers() {
      const payload = {
        eventId: this.event.id,
        data: this.competitors.map(competitor => ({
          competitorId: competitor.id,
          startNumber: 0
        }))
      };

      if (confirm(this.$i18n.t("competitors.startNumber.confirmation"))) {
        this.loading = true;

        this.setCompetitorStartnumbers(payload)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    assignStartNumbers(override) {
      let competitorData = this.competitors.map(competitor => ({
        competitorId: competitor.id,
        startNumber: competitor.startNumber
      }));

      competitorData = this.shuffleArray(competitorData);

      if (override) {
        for (let i = 0; i < competitorData.length; i++) {
          competitorData[i].startNumber = i + 1;
        }
      } else {
        for (let i = 0; i < competitorData.length; i++) {
          let counter = 0;
          if (competitorData[i].startNumber === 0) {
            let temp;
            do {
              counter += 1;
              temp = competitorData.filter(
                competitor => competitor.startNumber === counter
              );
            } while (temp.length > 0);
            competitorData[i].startNumber = counter;
          }
        }
      }

      const payload = {
        eventId: this.event.id,
        data: competitorData
      };

      this.loading = true;
      this.setCompetitorStartnumbers(payload)
        .then(() => {
          this.loading = false;
          this.showStartNumberDialog = false;
        })
        .catch(() => {
          this.loading = false;
          this.showStartNumberDialog = false;
        });
    },
    shuffleArray(array) {
      let currentIndex = array.length;
      let temporaryValue;
      let randomIndex;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
.v-data-table--dense /deep/ tbody tr:nth-child(even) {
  background: #f7f7f7;
}
</style>
