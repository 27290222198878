/* eslint-disable */

const mixin = {
  methods: {
    formatRunTime(timestamp_100ns, decimalPlaces = 2, appendNegative = true) {
      if (timestamp_100ns === null || timestamp_100ns === undefined) {
        return "-";
      }
      let negative = false;
      if (timestamp_100ns < 0) {
        negative = true;
      }
      timestamp_100ns = Math.abs(timestamp_100ns);
      decimalPlaces = Math.max(0, Math.min(decimalPlaces, 7));
      let remainder = timestamp_100ns;
      const hours = String(Math.floor(remainder / 36000000000)).padStart(2, "0");
      remainder = Math.floor(remainder % 36000000000);
      const minutes = String(Math.floor(remainder / 600000000)).padStart(2, "0");
      remainder = Math.floor(remainder % 600000000);
      const seconds = String(Math.floor(remainder / 10000000)).padStart(2, "0");
      remainder = Math.floor(remainder % 10000000);

      remainder = String(remainder).padStart(7, "0");

      let remStr = String(remainder).padEnd(7, "0").substring(0, decimalPlaces);

      let runTime = `${hours}:${minutes}:${seconds}`;
      runTime = decimalPlaces > 0 ? `${runTime}.${remStr}` : `${runTime}`;
      runTime = negative && appendNegative ? `-${runTime}` : `${runTime}`;

      return runTime;
    }
  }
};

export default mixin;
