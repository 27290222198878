const mixin = {
  methods: {
    convertToSpeedUnit(speed, appendSpeedUnit = true, prefix = true) {
      let speedPrecision = [
        "1u",
        "1/10u",
        "1/100u",
        "1/1000u",
        "1/10000u",
        "1/100000u"
      ].findIndex(el => el === this.event.setup.speedPrecision);
      speedPrecision === -1 ? 1 : speedPrecision;

      if (this.event.setup.speedUnit !== null) {
        if (!prefix) {
          speed = Math.abs(speed);
        }
        switch (this.event.setup.speedUnit) {
          case "KM_H":
            return `${Number(speed * 3.6).toFixed(speedPrecision)}${
              appendSpeedUnit ? " km/h" : ""
            }`;
          case "MP_H":
            return `${Number(speed * 2.23694).toFixed(speedPrecision)}${
              appendSpeedUnit ? " mp/h" : ""
            }`;
          default:
            return `${Number(speed).toFixed(speedPrecision)}${
              appendSpeedUnit ? " m/s" : ""
            }`;
        }
      }

      return null;
    }
  }
};

export default mixin;
