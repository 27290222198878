/* eslint-disable */

const mixin = {
  methods: {
    getCompetitorName(competitor) {
      const showLastName =
        this.event.competitorData.data[1].accessControl.other.read ||
        (this.isOwner &&
          this.event.competitorData.data[1].accessControl.competitor.write);
      const showFirstName =
        this.event.competitorData.data[2].accessControl.other.read ||
        (this.isOwner &&
          this.event.competitorData.data[2].accessControl.competitor.write);

      let name = "";

      name += showLastName
        ? this.formatName(
            competitor.userData.lastName,
            this.event.competitorData.data[1].view.format
          )
        : "";
      name += showFirstName
        ? " " +
          this.formatName(
            competitor.userData.firstName,
            this.event.competitorData.data[2].view.format
          )
        : "";

      return name;
    },
    formatName(name, format) {
      if (!name) return "";
      if (!format) return name;

      name = String(name);

      switch (format.toUpperCase()) {
        case "UPPERCASE":
          return name.toUpperCase();
        case "SHORT":
          return name.length > 1 ? name.slice(0, 1) + "." : name;
        case "DEFAULT":
        default:
          return name;
      }
    }
  }
};

export default mixin;
